// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-games-choppy-orc-js": () => import("./../../../src/pages/games/choppy-orc.js" /* webpackChunkName: "component---src-pages-games-choppy-orc-js" */),
  "component---src-pages-games-dozenbear-js": () => import("./../../../src/pages/games/dozenbear.js" /* webpackChunkName: "component---src-pages-games-dozenbear-js" */),
  "component---src-pages-games-greedy-mimic-js": () => import("./../../../src/pages/games/greedy-mimic.js" /* webpackChunkName: "component---src-pages-games-greedy-mimic-js" */),
  "component---src-pages-games-hasty-shaman-js": () => import("./../../../src/pages/games/hasty-shaman.js" /* webpackChunkName: "component---src-pages-games-hasty-shaman-js" */),
  "component---src-pages-games-jelly-bots-js": () => import("./../../../src/pages/games/jelly-bots.js" /* webpackChunkName: "component---src-pages-games-jelly-bots-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-games-one-trick-mage-js": () => import("./../../../src/pages/games/one-trick-mage.js" /* webpackChunkName: "component---src-pages-games-one-trick-mage-js" */),
  "component---src-pages-games-orcs-vs-elves-js": () => import("./../../../src/pages/games/orcs-vs-elves.js" /* webpackChunkName: "component---src-pages-games-orcs-vs-elves-js" */),
  "component---src-pages-games-sleepy-knight-js": () => import("./../../../src/pages/games/sleepy-knight.js" /* webpackChunkName: "component---src-pages-games-sleepy-knight-js" */),
  "component---src-pages-games-sticky-sorcerer-js": () => import("./../../../src/pages/games/sticky-sorcerer.js" /* webpackChunkName: "component---src-pages-games-sticky-sorcerer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

